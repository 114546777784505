import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Deload Week`}</strong></p>
    <p>{`Push Press/Jerk Mobility: Shoulder, wrist, lat, forearm & core mobility`}</p>
    <p>{`then, with a :30 running clock:`}</p>
    <p>{`500M Row`}</p>
    <p>{`100 Jump Rope Singles`}</p>
    <p>{`20 Stationary Lunges (10/leg)`}</p>
    <p>{`15 Pushups`}</p>
    <p>{`10 Ring Rows`}</p>
    <p>{`5 Inch Worms`}</p>
    <p>{`Not scored.`}</p>
    <p>{`*`}{`focus on moving correctly at 60-70% intensity.`}</p>
    <p><strong parentName="p">{`*`}{`Tonight’s 6pm class will be cancelled so that we can set up for
Saturday’s Granite Games Winter Throwdown.  If you can help us set up
please let us know.  The more the merrier!  We’ll start setting up at
6:00 sharp.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The gym will be closed Saturday for the Winter Throwdown but if you
aren’t competing or volunteering come out and cheer on the competitors!
 The first heat starts at 9:00am.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      